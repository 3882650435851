:root {
    --background-color: rgb(42, 42, 42);
    --background-color-input: #212121;
    --header-color: #7A7A7A;

}
.font-size-96 {
    font-size: 96px;
}




