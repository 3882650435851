.buttonStyle
  width: 123px
  height: 36px
  background: #212121
  border-radius: 25px
  border: none
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
.button__text
  font-style: normal
  font-weight: 900
  font-size: 16px
  line-height: 39px
  display: flex
  align-items: center
  text-align: center
  color: #7A7A7A
