.container
  display: flex
  align-items: center
  flex-direction: column
  align-content: center
  width: 95%
  margin: auto
  margin-top: 20px


.pop-up
  position: absolute
  opacity: 0
  z-index: 999
  padding: 10px
  border-radius: 10px
  transition: opacity 0.6s linear
  span
    color: #FFFFFF

.copied
  left: 71px
  top: 113px
  background-color: #7e828f
.newStream
  right: 18px
  bottom: 71px
  background-color: #434343
  border-radius: 15px
  opacity: 0
  display: flex
  align-items: center
  padding: 10px 20px
.newStreamText
  margin-left: 15px
  display: flex
  flex-direction: column
  justify-content: flex-start
  p, span
    text-align: start
  p
    font-size: 14px
    margin: 0
    margin-bottom: 5px
    font-weight: 500
    color: #FFFFFF
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  span
    font-size: 11px
    color: #B5B5B5
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)





@keyframes fadeIn
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0


.header
  display: flex
  justify-content: space-between
  width: 100%
  margin-top: 10px
  margin-bottom: 10px
.header__username, .header__balance
  font-weight: 900
  font-size: 14px
  text-align: right
  color: #7A7A7A
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
.header__balance
  font-size: 13px
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-left: 10px
.header__logout
  font-weight: 900
  font-size: 12px
  line-height: 29px
  text-align: right
  color: #4B4B4B
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)

.content
  width: 100%

.main__header
  align-self: flex-start
  font-size: 35px

.vertical__header
  font-style: normal
  font-weight: 900
  font-size: 40px
  color: #434343
  text-shadow: 2px 4px 20px #1F1F1F
  writing-mode: vertical-lr
  transform: scale(-1)
  @media (max-width: 900px)
    font-size: 35px
    writing-mode: unset
    transform: unset

.columns
  display: flex
  justify-content: space-between
  @media (max-width: 900px)
    flex-direction: column

.column-1, .column-3
  width: 120px
  white-space: nowrap
  overflow: hidden
  height: 75vh
  @media (max-width: 900px)
    height: unset
    margin: 20px 0
    width: 100%
    justify-content: flex-end
    display: flex
    position: relative


.column-1
  text-align: initial
  width: 85px
  @media (max-width: 900px)
    width: unset



.column-1-2
  width: 70%


.column-3-4
  width: 30%
  position: relative
  @media (max-width: 900px)
    flex-direction: row-reverse

.column-1-2, .column-3-4
  display: flex
  @media (max-width: 900px)
    width: 100%
    flex-direction: column


.column-2
  width: -moz-available
  width: -webkit-fill-available
  max-height: 75vh
  overflow: auto
  @media (max-width: 900px)
    max-height: 60vh


::-webkit-scrollbar
  display: none

table thead
  position: sticky
  top: 0
  z-index: 1
table thead tr th
  padding-bottom: 30px
  border-radius: 0 !important
tbody
  position: relative
  top: -20px

thead
  //background: linear-gradient(rgb(41,41,41), transparent)
  background: linear-gradient(0deg, rgba(41,41,41,0.121187850140056) 11%, rgba(41,41,41,41) 55%)
.header-container
  display: flex
  justify-content: center
  width: 100%

.header-content
  width: calc(95% - 30px)
  padding: 0 15px
  display: flex
  justify-content: space-between

.streams__table
  width: 100%

.streams__items
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.header__item-dates
  font-style: normal
  font-weight: 900
  font-size: 12px
  text-align: center
  text-decoration-line: underline
  color: #7A7A7A
  text-shadow: 2px 4px 20px #1F1F1F
  @media (max-width: 1000px)
    display: flex
    flex-direction: column
    font-size: 10px
  @media (max-width: 480px)
    display: none
.datePicker
  //background-color: #1C1C1C
table
  border-collapse: collapse
  border: none

td:first-child,
th:first-child
  border-radius: 20px 0 0 20px

td:last-child,
th:last-child
  border-radius: 0 20px 20px 0
  width: 170px

.streams__item
  max-width: 800px
  width: 95%
  height: 70px

td
  height: 50px
  background-color: rgb(67, 67, 67)
  //margin-bottom: 20px

td:nth-child(2)
  width: 130px

//div[class*='streams__item-']
//  background-color: rgb(67, 67, 67)
.streams__item-title
  position: relative

.streams__item-title span
  overflow: hidden
  max-width: 15ch
  text-overflow: ellipsis
  text-align: center
  display: block
  white-space: nowrap
  @media (max-width: 900px)
    max-width: 65px
.streams__item-title:after
  content: attr(data-title)
  position: absolute
  bottom: -55px
  left: -10px
  color: #fff
  background-color: #343434
  padding: 6px
  display: none
  border-radius: 5px


.streams__item-title:hover:after
  display: block

.streams__item-title:active:after
  display: block

.streams__item-container
  display: flex
  width: calc(100% - 30px)
  flex-direction: row
  flex-wrap: nowrap
  justify-content: space-between
  align-items: center
  height: 70px
  padding: 0 15px


td:nth-child(2),
th:nth-child(2)
  @media (max-width: 1200px)
    border-radius: 20px 0 0 20px
  @media (max-width: 900px)
    border-radius: 0
  @media (max-width: 480px)
    border-radius: 20px 0 0 20px



.streams__item-logo, .header__item-empty-logo
  @media (max-width: 1200px)
    display: none
  @media (max-width: 900px)
    display: block
  @media (max-width: 480px)
    display: none


.streams__item-dm
  display: flex
  flex-direction: column
.down-gradient
  position: absolute
  bottom: 0
  height: 30px
  width: 100%
  background-color: transparent
.popup__item-logo, .popup__item-logo img
  width: 55px
  height: 55px
  border-radius: 10px
  object-fit: cover

.streams__item-logo
  border-bottom-left-radius: 25px
  border-top-left-radius: 25px

.streams__item-logo img, .offers__item-logo img, .header__item-empty-logo
  width: 50px
  height: 50px
  border-radius: 10px
  object-fit: cover
.streams__item-name
  display: flex
  flex-direction: column
  align-items: center
  @media (max-width: 480px)
    margin-right: -20px
.streams__item-name, .header__item-empty-name
  font-style: normal
  font-weight: 700
  font-size: 12px
  color: #B5B5B5
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  width: 100%

.streams__item-date
  display: flex
  flex-direction: column
  align-items: center
.streams__item-date, .header__item-empty-date
  font-style: normal
  font-weight: 500
  font-size: 12px
  color: #877F7F
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  @media (max-width: 480px)
    display: none

.streams__item-y
  @media (max-width: 1350px)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center


.streams__item-clicks, .streams__item-reg-cr, .streams__item-dep-cr, .streams__item-income, .streams__item-epc
  font-weight: 700
  font-size: 12px
  color: #B5B5B5
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  @media (max-width: 480px)
    display: flex
    flex-direction: column


.header__item-clicks, .header__item-reg-cr, .header__item-dep-cr, .header__item-income, .header__item-epc
  font-weight: 900
  font-size: 16px
  text-align: center
  color: #4B4B4B
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  cursor: pointer
  user-select: none
  @media (max-width: 480px)
    font-size: 12px
    overflow: hidden
    white-space: nowrap
    //max-width: 3ch
.streams__item-link
  width: 100%
  display: flex
  justify-content: center
  cursor: pointer


.streams__item-link-container
  display: flex
  align-items: center
  justify-content: center
  background-color: rgb(55,55,55) !important
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.2)
  border-radius: 19px
  width: 90%
  height: 35px

.streams__item-link-copy-pic
  background-color: transparent !important

.streams__item-link-copy-pic img
  width: 20px
  height: 20px
  margin-right: 3px

.streams__item-link-copy-link
  font-weight: 500
  font-size: 12px
  color: #BCBCBC
  background-color: transparent !important
  //@media (max-width: 1000px)
  //  display: none
  @media (max-width: 900px)
    display: block
  @media (max-width: 480px)
    display: none


.column-4
  width: 100%
  max-height: 75vh
  overflow: auto
  position: relative
.filters
  background: linear-gradient(0deg, rgba(41,41,41,0.121187850140056) 0%, rgba(41,41,41,41) 39%)
  position: sticky
  top: 0
  z-index: 1
  padding-bottom: 10px
  @media (max-width: 900px)
    height: 90px
    display: flex
    justify-content: space-between
    margin-top: 40px
.hashtags
  max-width: 600px
  @media (max-width: 900px)
    width: 100%

.search
  width: 100%
  display: flex
  @media (max-width: 900px)
    justify-content: flex-end

ul
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  align-items: flex-end
  margin-bottom: 20px
  flex-wrap: wrap
  @media (max-width: 900px)


li
  text-decoration: none
  margin-right: 10px



ul .unActive
  user-select: none
  font-style: normal
  font-weight: 900
  font-size: 16px
  color: #4B4B4B
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  //padding: 4px 0
  height: 21.5px



ul .active
  user-select: none
  font-weight: 900
  font-size: 18px !important
  text-align: center
  color: #7A7A7A !important
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  height: 21.5px
  //padding: 0

.offers__items
  margin-top: -5px
  margin-bottom: 50px
  @media (max-width: 900px)
    display: flex
    flex-wrap: wrap

.offers__item
  margin-bottom: 10px
  max-width: 320px
  width: 100%
  @media (max-width: 900px)
    margin-right: 10px
    max-width: unset
    width: calc(100% / 3 - (10px * 2 / 3))
  @media (max-width: 750px)
    width: calc(100% / 2 - (10px / 2))
  @media (max-width: 500px)
    width: 100%

.offers__item:nth-child(3n)
  @media (max-width: 900px)
    margin-right: 0
  @media (max-width: 750px)
    margin-right: 10px
  @media (max-width: 500px)
    margin-right: 0
.offers__item:nth-child(2n)
  @media (max-width: 750px)
    margin-right: 0
    @media (max-width: 500px)
      margin-right: 0

.streams__item:last-child
  margin-bottom: 30px


.addOffer
  position: absolute
  font-weight: 800
  right: 5px
  top: -11px
  font-size: 23px
  color: #D6D6D6
  cursor: pointer
  display: none
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  user-select: none


.offers__item-text
  display: flex
  width: 100%
  @media (max-width: 1100px)
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    height: 58px

.offers__item-container
  height: 70px
  flex-direction: row
  flex-wrap: nowrap
  align-items: center
  display: flex
  justify-content: flex-start

.offers__item-logo-container
  position: relative

.offers__item-logo-container:hover > .offers__item-logo
    filter: brightness(0.5)

.offers__item-logo-container:hover > .addOffer
  display: block




.offers__item-logo
  margin-right: 10px
  position: relative


.offers__item-logo img
  width: 60px
  height: 60px
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: -1
  //mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) -20%, rgb(0 0 0) 75%)




.offers__item-name
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  max-width: 130px
  @media (max-width: 1100px)


.offers__item-description
  font-weight: 500
  font-size: 10px
  display: flex
  align-items: flex-end
  color: #B5B5B5
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  @media (max-width: 1100px)
    font-size: 9px
    margin-bottom: 3px


.offers__item-title
  font-style: normal
  font-weight: 500
  font-size: 14px
  color: #FFFFFF
  text-align: initial
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  @media (max-width: 1100px)
    font-size: 12px

.offers__item-info
  margin-left: auto
  @media (max-width: 1100px)
    margin-left: 0

.offers__item-type
  margin-bottom: 10px
  margin-top: 5px
  @media (max-width: 1100px)
    font-size: 12px
    margin-bottom: 3px
    margin-top: 0

.offers__item-stats
  @media (max-width: 1100px)
    display: flex


.offers__item-country, .offers__item-hashtag
  font-style: normal
  font-weight: 600
  font-size: 10px
  display: flex
  align-items: flex-end
  text-align: right
  color: #FFFFFF
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)

.offers__item-payout
  @media (max-width: 1100px)
    margin-right: 5px
    font-size: 9px


.offers__item-payout, .offers__item-baseline
  font-style: normal
  font-weight: 500
  font-size: 10px
  text-align: right
  color: #B5B5B5
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1)
  @media (max-width: 1100px)
    text-align: left

.react-datepicker
  background-color: var(--background-color-input) !important
  border: none !important
  cursor: pointer
.react-datepicker__header
  background-color: var(--background-color-input) !important
  border: none !important
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after, .react-datepicker__triangle::before
  border-bottom-color:  var(--background-color-input) !important
.react-datepicker__day-name, .react-datepicker__current-month
  color: rgb(212,214,201) !important
.react-datepicker__navigation-icon::before, .react-datepicker__navigation-icon::after
  width: 7px !important
  height: 7px !important
.react-datepicker__day
  color: rgb(158,158,158) !important
.react-datepicker__day--keyboard-selected
  background-color: rgba(67, 67, 67,0.8) !important
.react-datepicker__day:hover
  background-color: rgba(67, 67, 67,0.5) !important
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),.react-datepicker__day--in-range
  background-color: rgba(67, 67, 67,0.8) !important
  color: rgb(236, 236, 236) !important
  //105,78,227
.react-datepicker__day--in-range
  color: rgb(236, 236, 236) !important


.react-datepicker__input-container input
  background-color: transparent !important
  padding: unset !important
  text-align: center !important
  box-shadow: none !important
  margin-bottom: unset !important
  font-style: normal
  font-weight: 900
  font-size: 16px
  text-align: center
  color: #7A7A7A
  text-shadow: 2px 4px 20px #1F1F1F
  cursor: pointer
.react-datepicker__close-icon
  display: none !important


.mobileHidden
  @media (max-width: 480px)
    display: none
.mobile-calendar
  display: none
  @media (max-width: 480px)
    display: flex
  input
    text-align: left !important







