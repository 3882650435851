.signin__content
  display: flex
  flex-direction: column
  align-items: center
  height: 100vh
  justify-content: center
  scrollbar-width: none
  overflow-y: scroll

.signin__header
  font-style: normal
  font-weight: 900
  font-size: 46px
  display: flex
  align-items: center
  text-align: center
  color: #7A7A7A
  text-shadow: 2px 4px 20px #1F1F1F
  margin-bottom: 20px
.signin__form
  width: 100%
  display: flex
  flex-direction: column
  align-items: center

::-webkit-scrollbar
  display: none
