@import "../../../constants/variables.css"
/*input{*/
/*    background-color: #212121;*/
/*}*/
input
  background-color: var(--background-color-input)
  border: none
  box-shadow: inset 5px 5px 5px #1C1C1C
  border-radius: 5px
  height: 27px
  margin-bottom: 10px
  max-width: 324px
  width: 100%
  padding-left: 15px
  color: white
